// src/components/Questionnaire.js
import React, { useState } from 'react';

function Questionnaire() {
  const [checkedCount, setCheckedCount] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    
    // Get checked count
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let count = 0;
    checkboxes.forEach(checkbox => {
      if(checkbox.checked) count++; 
    });
  
    // Clear checkboxes
    checkboxes.forEach(ch => ch.checked = false);
  
    // Update state with score
    setCheckedCount(count); 
    setSubmitted(true);
  }

  // Clear checkboxes 
  const clearCheckboxes = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(ch => ch.checked = false);
  }

  const questions = [
    "Are your peers/coworkers scared that you might be better than them at everything and that they will be out of job if they try to do whatever it is I'm doing",
    "Do you frequently drop everything and work non-stop on a single thing if decide on the right thing to do",
    "Are you an exceptionally clear writer?",
    "Are you fast when it matters, slow when it doesn't?",
    "Do you really get 'if you're not sure what to do, just do the right thing'?",
    "Do you deliver on an agreed schedule, even if it means getting best effort rather than the perfect thing?",
    "Can you figure out assignments from people you respect on their own and deliver way above expectations on them?",
    "Are you ahead of your superiors on important thoughts, questions, insights, achievements (if very young: ahead of them at your age)",
    "Are you clearly much better than your superiors at >=2 important things",
    "Do you seriously ask the biggest questions & do their best to go all the way answering them?",
    "Do you keep promises?",
    "Does the following statement describe you 'impatient yet patient; brilliant yet dumb'",
    "Do ask questions that make people around you smarter and more specific",
    "Are you not scared of interrupting people",
    "Do you do what's required to get where they want to get (rather than only doing what they're good at)",
    "Do you have specific, correct hot takes (if young, on the path there)",
    "Have you delivered a ton of interesting projects that weren't helpful for anything in particular (whether it's writing, coding, leading or anything else)?",
    "Do you learn really fast?",
    "Do your actions reflect your beliefs?",
    "Do you have a history of embarrassing cold emails?",
    "Do you have a promise of good taste?",
    "Are competent people consistently surprised how you've been able to do what you've accomplished?",
    "Do you take ideas seriously?",
    "Are you a fast typer?",
    "Are you ok with being low status & not afraid to do things that are not viewed as “normal”?",
    "Do you consistently surprise your superiors or other competent people",
    "Are you not afraid to majorly change a (successful) career path",
    "Did you drop out of undergrad or grad school (for non-academics)",
    "Would Alexey Guzey feel embarrassed reading your resume?",
    "Have had 6 months+ periods of unemployment/underemployment",
    "Don't give up (eventually)",
    "See the future",
    "Don't settle for proxies, zoom all the way in & out",
    "Do you keep doing what they believe is the right thing in the absence of any positive feedback or only negative (even if only perceived) feedback from others",
    "Have you thought through everything?",
    "Do you use first principles when there are no priors & use priors when there are priors?",
    "Are you extremely curious?"
  ];
  
  return (
    <div style={{ flexDirection: 'column' , maxWidth:'90vw', wordWrap: 'break-word' }}>
      {!submitted &&
        <div>
          <form onSubmit={handleFormSubmit} style={{ textAlign: 'left' }}>
            {questions.map((question, index) => (
              <div key={index} className="question">
                <label>
                  <span style={{ textAlign: 'right' }}>{index + 1}.</span>
                  <input
                    type="checkbox"
                    style={{ marginLeft: '10px', marginTop: '7px' }}
                  />
                  {question}
                </label>
              </div>
            ))}
          </form>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop:'70px' }}>
            <input
              type="submit"
              value="Submit"
              style={{
                background: 'black',
                color: 'white',
                fontWeight: 'bold',
                padding: '20px',
                border: 'none',
                borderRadius: '15px',
                marginRight:'20px'
              }}
              onClick={handleFormSubmit}
            />
            <input
              type="button"
              value="Clear All Checkboxes"
              style={{
                background: 'black',
                color: 'white',
                fontWeight: 'bold',
                padding: '20px',
                border: 'none',
                borderRadius: '20px'
              }}
              onClick={clearCheckboxes}
            />
          </div>
        </div>
      }
      <div style={{
        textAlign: 'left',
        marginTop: '40px',
        marginBottom: '50px',
        marginLeft: ' 5px'
      }}>
  
      </div>
      {checkedCount > 0 && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          maxWidth:'90vw',
          wordWrap: 'break-word'
        }}>
          <p style={{
            fontSize: '32px',
            fontWeight: 'bold',
            lineHeight: '1.5em',
            maxWidth:'90vw',
            wordWrap: 'break-word'
          }}>
            Your Score: <br />
            <span style={{ fontSize: '48px', color: 'red' }}>
              {checkedCount}
            </span>
          </p>
        </div>
      )}
    </div>
  );
}
export default Questionnaire
