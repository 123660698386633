import './App.css';
import Questionnaire from './pages/questionnaire.js';

function App() {
  return (
    <div className="App">
      <h1 style={{fontSize: '3em', fontWeight: 'bold', textAlign: 'center', paddingTop: '40px'}}>
        The Genius Purity Test.
      </h1>

      <p style={{
        color: 'gray', 
        textAlign: 'center',
        width: '50%',
        margin: '0 auto',
        paddingBottom: '30px',
        maxWidth:'90vw',
        wordWrap: 'break-word'
      }}>
        Are you as special as you think you are? <br></br> Inspired by: https://guzey.com/talent/
      </p>
      <p style={{ 
        textAlign: 'center',
        width: '50%',
        margin: '0 auto',
        paddingBottom: '40px',
        maxWidth:'90vw',
        wordWrap: 'break-word'
      }}>Click on every item you have done.</p>

      <Questionnaire></Questionnaire>
    </div>
  );
}

export default App;
